<template>
  <div style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-row>
                <v-col class='d-flex flex-row align-center'>
                  <h1>Sync Data</h1>
                  <v-progress-circular
                      indeterminate
                      color="green"
                      v-if="loader"
                      style="margin-left: 10px;"
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="true">Coupons</v-btn>
                  <br>
                  <v-btn @click="customersSyncDownload">Customers Download</v-btn>
                  <br>
                  <v-btn @click="true">Orders</v-btn>
                  <br>
                  <v-btn @click="productsSyncDownload">Products/Categories/Tags Download</v-btn>
                  <v-btn @click="true">Products/Categories/Tags Upload</v-btn>
                  <br>
                  <v-row>
                    <v-col>
                      <h2>Products CSV Upload</h2>
                      <v-file-input label="Products File" @change="onAddFiles"></v-file-input>
                      <v-progress-circular
                          v-if="productsFileLoad"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                      <v-btn v-else @click="uploadProductsCSV">Products CSV Upload</v-btn>
                    </v-col>
                  </v-row>
                  <br>
                  <v-btn @click="true">Product Attributes</v-btn>
                  <br>
                  <v-btn @click="productQuantitiesInit">Product Quantities Init</v-btn>
                  <br>
                  <v-btn @click="productQuantitiesGen">Product Quantities Gen</v-btn>
                  <br>
                  <v-btn @click="generateUsernames">Generate Usernames</v-btn>
                  <br>
                  <v-btn @click="generateProductTypes">Generate Product Types Join</v-btn>
                  <v-row>
                    <v-col>
                      <h2>Data Import Parser</h2>
                      <v-text-field label="Table Name" v-model="tableName" type="text"/>
                      <v-file-input label="Table Data File" @change="onFileImport"></v-file-input>
                      <v-progress-circular
                          v-if="fileLoading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                      <v-btn v-else @click="uploadImportFire">Products CSV Upload</v-btn>
                    </v-col>
                  </v-row>
                  <br>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <h1>Creating Data</h1>
              <span>
                <v-btn @click="createUsers">Create Users (10)</v-btn>
                <v-btn @click="createProducts">Create Products (50)</v-btn>
                <v-btn @click="createSuppliers">Create Suppliers (10)</v-btn>
                <v-btn @click="stockProducts">Stock Products</v-btn>
                <v-btn @click="createCustomers">Create Customers (10)</v-btn>
                <v-btn @click="createPurchaseOrders">Create POs (10)</v-btn>
              </span>
              <h1>Writing/Backing Up Data</h1>
              <span>
                <v-btn @click="saveUsers">Save Users</v-btn>
                <v-btn @click="saveProducts">Save Products</v-btn>
                <v-btn @click="saveSuppliers">Save Suppliers</v-btn>
              </span>
              <h1>Reading New Data</h1>
              <span>
                <v-btn @click="readUsers">Read Users</v-btn>
                <v-btn @click="readProducts">Read Products</v-btn>
                <v-btn @click="readSuppliers">Read Suppliers</v-btn>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,

        productsFileLoad: false,
        file: '',

        fileLoading: false,
        importFile: '',
        tableName: "",
      }
    },
    async mounted(){
    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      async productsSyncDownload(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productsdownload`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async customersSyncDownload(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/customersdownload`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async uploadProductsCSV(){
        try{
          this.productsFileLoad = true;
          if(!this.file) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("name", this.file.name);

          let fileUpload = await axios.post(`${this.getEndpoint}/api/sync/productscsvupload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.productsFileLoad = false;
        }
      },
      onAddFiles(file){
        this.file = file;
      },
      async productQuantitiesInit(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productQuantitiesInit`)
          console.log(res);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async productQuantitiesGen(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productQuantitiesGen`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async generateUsernames(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/generateusernames`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async generateProductTypes(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/productsTypeGen`)
          console.log(res.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async uploadImportFire(){
        try{
          this.fileLoading = true;
          if(!this.importFile) throw "There are no files";

          let formData = new FormData();
          formData.append("file", this.importFile);
          formData.append("name", this.importFile.name);

          let data = await axios.post(`${this.getEndpoint}/api/sync/csvimport?table=${this.tableName}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          console.log(data);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.fileLoading = false;
        }
      },
      onFileImport(file){
        this.importFile = file;
      },
      async createUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createUsers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createCustomers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createCustomers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async stockProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/stockProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async createPurchaseOrders(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/createPurchaseOrders`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveUsers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async saveSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/saveSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readProducts(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readProducts`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readUsers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readUsers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
      async readSuppliers(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/sync/readSuppliers`)
          if(res.data.error) throw res.data.error
          console.log(res.data.data);
        } catch (error) {
          console.error(error)
        }
        finally {
          this.loader = false;
        }
      },
    }
  }
</script>
